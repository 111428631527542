export default {
    ru: {
        main: {
            add: 'Добавить',
            create: 'Создать',
            edit: 'Редактировать',
            save: 'Сохранить',
            back: 'Назад',
            delete: 'Удалить',
            logout: 'Выйти',
            login: 'Войти',
            upload: 'Загрузить',
            attachImage: 'Прикрепить картинку',
            villa: 'Номер виллы',
            fullName: 'Ф.И.О',
            email: 'Почта',
            phone: 'Телефон',
            password: 'Пароль',
            entrance: 'Вход',
            status: 'Статус',
            actions: 'Действия',
            service: 'Платная услуга | Платные услуги',
            category: 'Категория | Категории',
            resident: 'Житель | Жители',
            invoice: 'Квитанция | Квитанции',
            bill: 'Счет | Счета',
            ticket: 'Заявка | Заявки',
            wdTicket: 'Заявка ГО | Заявки ГО',
            ticketUk: 'Заявка | Заявки УК',
            aLaCartServices: 'Услуги A\'la carte',
            price: 'Цена',
            month: 'Месяц',
            isPaid: 'Статус оплаты',
            excel: 'Excel',
            address: 'Адрес',
            period: 'Период',
            billingDate: 'Дата оплаты счета',
            title: 'Заголовок',
            description: 'Описание',
            broadcast: 'Отправить всем',
            announcement: 'Объявление | Объявления',
            close: 'Закрыть',
            deleteConfirm: 'Вы уверены, что хотите удалить?',
            yes: 'Да',
            cancel: 'Отмена',
            file: 'Файл',
            publicationDate: 'Дата публикации',
            typeHere: 'Сообщение',
            amount: 'Сумма',
            costAmount: 'Общий расход',
            paymentAmount: 'Всего к оплате',
            documentNumber: 'Номер документа',
            utilityInfo: 'Информация об услуге',
            usageBefore: 'Открытие чтения',
            usageAfter: 'Закрытие чтения',
            usage: 'Баланс',
            totalAmount: 'Итого',
            totalConsumption: 'Общее потребление',
            totalAmountTl: 'Общая сумма ТЛ',
            internetPackage: 'Интернет-пакет',
            totalDueAmount: 'Общая сумма задолженности',
            villaSqM: 'Вилла м^2',
            chat: 'Чат',
            notification: 'Уведомление | Уведомления',
            compass: 'Пользователи "Компаса"',
            generate: 'Генерация аккаунта',
            generateBtn: 'Сгенерировать',
            ad: 'Объявление | Объявления',
            voting: 'Голосование | Голосования',
            survey: 'Опрос | Опросы',
            access_systems: 'Системы доступа',
            employee: 'Сотрудник | Сотрудники',
            dispatcher: 'Диспетчер | Диспетчеры',
            payment: 'Платеж | Платежи',
            room: 'Помещение | Помещения',
            managementCompany: 'Управляющая компания',
            osi: 'ОСИ',
            osiManagement: 'Управление ОСИ',
            uk: 'УК',
            ukManagement: 'Управление УК',
            siteManagement: 'Управление сайтом',
            access_system: 'Системы доступа',
            news: 'Новости на сайте',
            mcEmployees: 'Сотрудники УК',
            wdEmployees: 'Сотрудники ГО',
            bank: 'Банк',
            protocol: 'Протокол',
            createOsi: 'Регистрация ОСИ',
            initiators: 'Инициативная группа | Инициативные группы',
            newVotings: 'Легитимное голосование',
            ustav: 'Устав',
            myOsi: 'ОСИ',
            leader: 'Смена председателя',
            house: 'Дом | Дома',
            application: 'Заявки на регистрацию',
            changeLeader: 'Заявки на смену руководства',
            ecpSign: 'Подписать документ',
            estimation: 'Смета | Сметы',
            checkOwner: 'ЧекОунер',
            edu: 'Сертификация ОСИ',
            kzhkUsers: 'Пользователи КЖК',
            logs: 'Логи'
        },
        status: {
            new: 'Новый',
            accepted: 'Принят',
            completed: 'Завершен',
            paymentExpected: 'Ожидание оплаты',
            error: 'Ошибка'
        }
    }
}